import React from 'react'
import './About.css'
import fadil from './fad.jpg'
import js from './icons/js.svg'
import java from './icons/java.svg'
import python from './icons/python.svg'
import htm_l from './icons/html5.svg'
import cs_s from './icons/css.svg'
import php from './icons/php.svg'
import nodejs from './icons/node.svg'
import reac_t from './icons/react.svg'
import react_native from './icons/react.svg'
import dbs from './icons/db.svg'



const About = () => {
    return (
        <div className="about">
            <div className="my_pic">
                <img src={fadil} alt="Fadil" />
            </div>
            <div className = "about_items">
            <div className = "introduction">
                <h3>Introduction</h3>
            </div>
            <div className = "personal_intro">
                <p>
                I have a Master's Degree in Computer Science and have worked with Public Services Commission of Ghana for 2 and half years as 
                an information technology officer on Human Resource Management Information System<span>(HRMIS-ORACLE)</span>.
                </p><p>
                 Prior to that, I have taught several computer science courses at the University for 
                 Development Studies for one year. 
                 </p>
                 <p>I have a strong analytical and problem solving skills and always see a project to its completion.

                </p>
            </div>
            <div className = "technologies">
                <h3>Technologies</h3>
               
            <div className = "languages">
                       <h4>Languages</h4>
                       <div className = "list_of_languages">
                        <div className = "java tooltip">
                            <img src = {java} alt="Java" />
                            <span className ="tooltip_text">Java</span>
                        </div>

                        <div className = "python  tooltip">
                            <img src = {python} alt="Python" />
                            <span className ="tooltip_text">Python</span>
                        </div>
                   
                        <div className = "js tooltip">
                            <img src = {js} alt="JavaScript" />
                            <span className ="tooltip_text">JavaScript</span>
                        </div>
                    
                        <div className = "htm tooltip">
                            <img src = {htm_l} alt="HTML" />
                            <span className ="tooltip_text">Html5</span>
                        </div>
                   
                        
                        <div className = "csss tooltip">
                            <img src = {cs_s} alt="CSS" />
                            <span className ="tooltip_text">CSS</span>
                        </div>

                        <div className = "sql tooltip">
                            <img src = {dbs} alt="MySQL/Oracle" />
                            <span className ="tooltip_text">MySQL</span>
                        </div>
    
                        <div className = "nodejs tooltip ">
                            <img src = {nodejs} alt="Nodejs" />
                            <span className ="tooltip_text">Nodejs</span>
                        </div>

                        <div className = "php tooltip">
                            <img src = {php} alt="PHP" />
                            <span className ="tooltip_text">PHP</span>
                        </div>
                        
                        <h4>Frameworks|Libraries</h4>

                        <div className = "reac_t tooltip">
                            <img src = {reac_t} alt="React" />
                            <span className ="tooltip_text">React</span>
                        </div>
                        <div className = "react_native tooltip">
                            <img src = {react_native} alt="React native" />
                            <span className ="tooltip_text">React Native</span>
                        </div>
                        
                        </div>
            </div>
        
            <div className = "tools">
                <h4>Tools</h4>
                <div className = "list_of_tools">
                    <button className = "git">Git</button>
                    <button className = "proteus">Proteus</button>
                    <button className = "azure">Azure DevOps</button>
                    <button className = "aws">AWS</button>
                    <button className = "unix">UNIX</button>
                    
                </div>
            </div>
            </div>
            </div>
            
        </div>
       
    )
}

export default About

import React from 'react'
import { NavLink} from 'react-router-dom'
import './Toolbar.css'

const Toolbar = () => {
    return (
        <div className="tool_bar active">
           
               <ul> 
                  <div className="home"><NavLink to='/' ><h1 className= "fa fa-home" 
                    >Home<hr className = "firsthr"/><hr className = "secondhr"/></h1></NavLink></div> 
                   <div className="projects_about">
                    <NavLink to= '/projects' >PROJECTS</NavLink>
                    <NavLink to= '/about'>ABOUT</NavLink>
                    </div>
                </ul>
        
        </div>
    )
}

export default Toolbar

import React, { Component } from "react";
import Card from "./Card";
import "./Works.css";


class Works extends Component {
  state = {
    showDiv1: true,
    showDiv2: true,
    showDiv3: true,
    showDiv4: true,
    showDiv5: true,

    isToggleOn1: true,
    isToggleOn2: true,
    isToggleOn3: true,
    isToggleOn4: true,
    isToggleOn5: true
  };

  handleShowPreviewBox1 = () => {
    this.setState({
      showDiv1: !this.state.showDiv1,
      isToggleOn1: !this.state.isToggleOn1,
      showDiv2: true,
      showDiv3: true,
      showDiv4: true,
      showDiv5: true,
  
    });
  };

  handleShowPreviewBox2 = () => {
    this.setState({
      showDiv2: !this.state.showDiv2,
      isToggleOn2: !this.state.isToggleOn2,
      showDiv1: true,
      showDiv3: true,
      showDiv4: true,
      showDiv5: true,
    });
  };

  handleShowPreviewBox3 = () => {
    this.setState({
      showDiv3: !this.state.showDiv3,
      isToggleOn3: !this.state.isToggleOn3,
      showDiv2: true,
      showDiv1: true,
      showDiv4: true,
      showDiv5: true,
    });
  };

  handleShowPreviewBox4 = () => {
    this.setState({
      showDiv4: !this.state.showDiv4,
      isToggleOn4: !this.state.isToggleOn4,
      showDiv2: true,
      showDiv3: true,
      showDiv1: true,
      showDiv5: true,
    });
  };

  handleShowPreviewBox5 = () => {
    this.setState({
      showDiv5: !this.state.showDiv5,
      isToggleOn5: !this.state.isToggleOn5,
      showDiv2: true,
      showDiv3: true,
      showDiv4: true,
      showDiv1: true,
    });
  };

  render() {
    const showHideClassName1 = this.state.showDiv1
      ? "showPreview open_preview"
      : "showPreview close_preview";
    const showHideClassName2 = this.state.showDiv2
      ? "showPreview open_preview"
      : "showPreview close_preview";
    // const showHideClassName3 = this.state.showDiv3
    //   ? "showPreview open_preview"
    //   : "showPreview close_preview";
    // const showHideClassName4 = this.state.showDiv4
    //   ? "showPreview open_preview"
    //   : "showPreview close_preview";
    const showHideClassName5 = this.state.showDiv5
      ? "showPreview open_preview"
      : "showPreview close_preview";
    return (
      <div>
        <div className="topics">
          <h1>Project - Topics</h1>
          {/* Start container for project on */}
          <div className="project">
            <Card number="1" />
            <h3>Viral Adds</h3>
          </div>
          <div className="description_of_project">
            A viral advertising website, clients register, place their ads that
            are being uploaded by the app admins. Promoters sign up and have
            access to this information and subsequently advertise them throught
            their social media handles{" "}
          </div>
          <div className="pre_visit_btn">
            <div className="prev_btns_spacer"></div>
            <button className="preview " onClick={this.handleShowPreviewBox1}>
              {this.state.isToggleOn1 ? "Preview project" : "Close Preview"}
              
            </button>

            <a
              href="https://viraladsgh.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="visit_site"
            >
              <span>&#9758;</span>Visit site
            </a>
          </div>

              <iframe  src= "https://viraladsgh.com/" 
              style ={{
                scrolling:"auto",
                border: "none",
                marginBottom: "4rem",
                padding: "10px",
                height: "500px",
                borderRadius: "4%"
                
              }}
              className={showHideClassName1} showDiv1={this.state.showDiv1}
              title = "viral"
               >
              </iframe>
         
     

          <div className="project">
            <Card number="2" />
            <h3>Wa Suntaa Supporting Club</h3>
          </div>
          <div className="description_of_project">
            A website to manage players, managers, supporters and to promote
            their club{" "}
          </div>
          <div className="pre_visit_btn">
            <div className="prev_btns_spacer"></div>
            <button className="preview" onClick={this.handleShowPreviewBox2}>
              {this.state.isToggleOn2 ? "Preview project" : "Close Preview"}
            </button>

            <a
              href="http://suntaasc.com/suntaa"
              target="_blank"
              rel="noopener noreferrer"
              className="visit_site"
            >
              <span>&#9758;</span>Visit site
            </a>
          </div>

          <iframe  src= "http://suntaasc.com/suntaa" 
              style ={{
                scrolling:"auto",
                border: "none",
                marginBottom: "4rem",
                padding: "10px",
                height: "500px",
                borderRadius: "4%"
                
              }}
              className={showHideClassName2} showDiv2={this.state.showDiv2}
              title = "suntaa"
               >
              </iframe>


              <div className="project">
            <Card number="3" />
            <h3>Crowdfunding</h3>
          </div>
          <div className="description_of_project">
            It is a web application to promote enterpeneurship in Ghana
            <span>(under construction)</span>
          </div>
          <div className="pre_visit_btn">
            <div className="prev_btns_spacer"></div>
            <button className="preview" onClick={this.handleShowPreviewBox5}>
              {this.state.isToggleOn5 ? "Preview project" : "Close Preview"}
            </button>
            <a href="http://ghanacrowdfunding.com/newgce.org/" className="visit_site">
              <span>&#9758;</span>Visit site
            </a>
          </div>

          <iframe  src= "http://ghanacrowdfunding.com/newgce.org/" 
              style ={{
                scrolling:"auto",
                border: "none",
                marginBottom: "4rem",
                padding: "10px",
                height: "500px",
                borderRadius: "4%"
                
              }}
              className={showHideClassName5} showDiv5={this.state.showDiv5}
              title = "crowdfunding"
               >
              </iframe>

          {/* <div className="project">
            <Card number="3" />
            <h3>A Framework for SQL Injection Detection</h3>
          </div>
          <div className="description_of_project">
            It is used to send attempted hack logs to DBA administrators or web
            application developers leveraging on the Machine learning{" "}
          </div>
          <div className="pre_visit_btn">
            <div className="prev_btns_spacer"></div>
            <button className="preview" onClick={this.handleShowPreviewBox3}>
              {this.state.isToggleOn3 ? "Preview project" : "Close Preview"}
            </button>

            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="visit_site"
            >
              <span>&#9758;</span>View code
            </a>
          </div>

          <iframe  src= "#" 
              style ={{
                scrolling:"auto",
                border: "none",
                marginBottom: "4rem",
                padding: "10px",
                height: "500px",
                borderRadius: "4%"
                
              }}
              className={showHideClassName3} showDiv3={this.state.showDiv3}
              title = "framework"
               >
              </iframe> */}
{/* 
          <div className="project">
            <Card number="4" />
            <h3>A POS for Pharmacies</h3>
          </div>
          <div className="description_of_project">
            It is a web application for the management of inventory and sales of
            Medicine by pharmacies{" "}
          </div>
          <div className="pre_visit_btn">
            <div className="prev_btns_spacer"></div>
            <button className="preview" onClick={this.handleShowPreviewBox4}>
              {this.state.isToggleOn4 ? "Preview project" : "Close Preview"}
            </button>

            <a href="#" className="visit_site">
              <span>&#9758;</span>Standalone
            </a>
          </div>

          <iframe  src= "#" 
              style ={{
                scrolling:"auto",
                border: "none",
                marginBottom: "4rem",
                padding: "10px",
                height: "500px",
                borderRadius: "4%"
                
              }}
              className={showHideClassName4} showDiv4={this.state.showDiv4}
              title = "standalone"
               >
              </iframe> */}

          
        </div>
      </div>
    );
  }
}
export default Works;

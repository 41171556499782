import React from 'react'
import Card from './Card'
import './Pages.css'
import Projects from './Projects'
import About from '../Pages/About'
import Dashboard from '../../Dashboard/Dashboard'
// import { Link } from 'react-router-dom'
// import {saveAs} from 'file-saver'

const Home = () => {
    return (
        <div className="home">
            {/* <div className="my_name">{props.name} Fadilul-lah Y. Issahaku</div> */}
            <Dashboard />
            <div className="skills_cv"> 
            <div className="skills">
            <div className="back_end">
            <Card number ='1'/>
            <h3>Back-end Development</h3>
            </div>
            <h4><q>A car without an engine is as good as a scrap </q></h4>
            <div className="back_end">
            <Card number ='2'/>
            <h3>Front-end Development</h3>
            </div>
            <h4><p>Progammers love back-end, arh well, without front-end
              we will be out of job. </p> 
              <p> Our clients favorite. 
                A poorly developed app with beautiful UI,
                </p>
                 <p>you are damn sure to be 
                in Business</p>
                </h4>
                <div className="back_end">
            <Card number ='3'/>
            <h3>Web UI/UX Design</h3>
            </div>
            
            <h4>
                <p>
                    We’ve all overheard conversations, walking down 
                
                hip streets of</p><p> the world’s tech capitals, 
                    discussions 
                </p>
                <p>about the great ‘UX’ of a product, 
                    or the poor ‘UI’ of a website
                </p>
                </h4>
        </div>
        <div className ="cv">
            <blockquote className="qt">
                <p>When you are commited to someone, 
                  you don't allow yourself to find perfection in someone else
               </p>
               <footer>~ Fadil <cite>In the Kingdome of Yassaanah</cite></footer>
              
               </blockquote>
               <a href = "/static/media/Fadil_CV.pdf" download  className="btn_cv_download">DOWNLOAD CV</a>
              
               
        </div>
        </div>
        <Projects />
        <About />
        </div>
    )
}

export default Home

import React from 'react'
import Works from './Works'
import './Works.css'



const Projects = () => {
    return (
        <div>
           <Works />
            <div></div>

        </div>
    )
}

export default Projects

import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import './App.css'
import Contact from './components/Contacts/Contact'
import Toolbar from './components/Toolbar/Toolbar'
import Footer from './components/Footer/Footer'
import Home from './components/Toolbar/Pages/Home'
import Projects from './components/Toolbar/Pages/Projects'
import About from './components/Toolbar/Pages/About'



class App extends Component{
   greeting = () =>{
     let dt = new Date();
     let hour = dt.getHours();
     return hour < 12 ? 'Good Morning! and Welcome to my Website' : 'Good Evening! and Welcome to my Website' 
   }
  render(){
    return (
      <div>

    <div className='app'>
     
      <Contact />
      <Toolbar />
      <h3 style={{textAlign: 'center'}}>{this.greeting()}</h3>
      <Route path = "/" exact component={Home} />
      <Route path = "/projects" exact component={Projects} />
      <Route path = "/about" exact component={About} />
     
    </div>
     <Footer />
    </div>
  )}
    
    
}

export default App
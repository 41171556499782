import React, { Component } from 'react'
import './Modal.css'
import fad from '../Toolbar/Pages/fad.jpg'


export default class Dashboard extends Component {

    state = {
        show: false
    }
 

 showModal = () =>{
     this.setState({
         show: !this.state.show

     })
 }
    
 

    hideModal = () =>{
        this.setState({
            show: false
        })
    }
    render() {
        return (
            <div className = "txt_color">
                <h1 onMouseDown= {this.showModal}>Fadilul-lah Y. Issahaku</h1>
                <Modal show ={this.state.show} handleClose= {this.hideModal}>
                <p><img src = {fad} alt= "Fadil" /></p>
                <div className ="my_details">
                <p><i>Fadilul-lah Yassaanah Issahaku</i></p>
                <p><i>BSc. Computer Science</i></p>
               <p> <i>MSc. Comp. Sci (Big Data)</i></p>
                </div>
               
                </Modal>
            </div>
        )
    }
}

const Modal = ({ handleClose, show, children}) => {

    const showHideClassName = show ? "modal display-block" : "modal display-none"
    return (
        <div className = {showHideClassName}>
            <section className = 'modal-main' onClick ={handleClose}>
                {children}
            </section>
            
        </div>
    )
}
import React from 'react'
// import { Link } from 'react-router-dom'
import './Contacts.css'

const Contact = () => {
    return (
        <div className="contact">
            <div className="email_call">
                <a href = "mailto:issakafadil@gmail.com" target ="_blank" rel = "noopener noreferrer" className="email fa fa-envelope-o">issakafadil@gmail.com</a>
                <a href = "tel:+233(0)201365454" target = "_blank" rel="noopener noreferrer" className="call fa fa-phone">...Please call +233(0)201365454</a>
            </div>
            <div className="linkedin_twitter">
                <a href="https://www.linkedin.com/in/yassaanah/" target ="_blank"  rel = "noopener noreferrer" className="linkedin fa fa-linkedin-square"></a>
                <a href = "https://twitter.com/yassaanah" target ="_blank"  rel ="noopener noreferrer" className="twitter fa fa-twitter-square"></a>
            </div>
        </div>

    )
}

export default Contact

import React from 'react'
import './Footer.css'


const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer_items">
           
            <ul>
          
    <span>&copy; </span>2020<a href="www.linkedin.com/in/yassaanah">LinkedIn</a>
                <a href="https://twitter.com/yassaanah">Twiter</a>
                <a href="https://github.com/Issakafadil">Github</a>
                
                <div className ="foot_spacer"></div>
                <a className="fadil" href="www.faditech.org">faditech.org</a>
            </ul>
            </div>
        
        
        </footer>
    )
}

export default Footer
